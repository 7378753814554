import React, { useEffect } from "react";
import "../styles/sections/about.scss";
import { StaticImage } from "gatsby-plugin-image";
import { motion, useAnimation } from "framer-motion";
import { delayed, growRight, fadeIn } from "../components/animations";
import { useInView } from "react-intersection-observer";
import { Link } from "gatsby";


growRight.visible.transition.delay = 0
growRight.visible.transition.duration = 0.6

// About Us Section on the INDEX page.

export function AboutUsSection() {
  const animation = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    }
  }, [animation, inView]);

  return (
    <section className="about-us-index">
      <motion.div ref={ref} className="container">
        <motion.h1
          variants={delayed(0.5, -200)}
          initial="hidden"
          animate={animation}
        >
          About us<span>.</span>
        </motion.h1>
        <section>
          <motion.hr
            className="divider"
            variants={growRight}
            animate={animation}
            initial="hidden"
          />
          <motion.h2
            variants={fadeIn(0.5)}
            animate={animation}
            initial="hidden"
          >
            Quality Roofing Since <span>1991</span>.
          </motion.h2>
          <motion.div className="blurb">
            <motion.p
              variants={fadeIn(0.5)}
              animate={animation}
              initial="hidden"
            >
              Here at West Coast Metal Roofing<span>,</span> we're a family
              company that's been doing roofing for over 30 years<span>,</span>{" "}
              delivering consistent<span>,</span>
              eye-catching results time after time<span>.</span> From
              neighborhood homes, to larger projects like Raging Waters
              <span>,</span> we're able to bring our expertise and craftsmanship
              to your next project<span>,</span> no matter the scale
              <span>.</span>
            </motion.p>
            {/* <div id="about-img-div">
              <StaticImage
                id="about-img"
                alt="Metal Panelling on side of residence"
                src="../images/house-4.jpg"
                width={600}
              />
            </div> */}
          </motion.div>
        </section>
        <motion.hr
          className="divider"
          variants={growRight}
          animate={animation}
          initial="hidden"
        />
        <motion.div
          variants={fadeIn(0.6)}
          animate={animation}
          initial="hidden"
          className="link-container"
        >
          <Link to="/">About us ></Link>
        </motion.div>
        <motion.hr
          className="divider"
          variants={growRight}
          animate={animation}
          initial="hidden"
        />
      </motion.div>
    </section>
  );
}

import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { delayed, growRight, fadeIn } from "../components/animations";
import { useInView } from "react-intersection-observer";
import { Link } from "gatsby";
import "../styles/sections/materials.scss";
import Shard from "../components/shard.js";

const shardVariant = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.6,
    }
  }
}

export function MaterialSection() {
  const animation = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    }
  }, [animation, inView]);
  return (
    <section ref={ref} className="material-section">
      <div className="container">
        <section>
          <motion.h1
            variants={delayed(0.5, -200)}
            animate={animation}
            initial="hidden"
          >
            Materials<span>.</span>
          </motion.h1>
          <motion.h2
            variants={fadeIn(0.5)}
            animate={animation}
            initial="hidden"
            id="material-subheader"
          >
            Choose <span>quality materials</span> from industry-trusted
            suppliers like <span>Berridge </span>
            and many more, all at <span>great prices</span>.
          </motion.h2>
        </section>
        <motion.hr
          className="divider"
          variants={growRight}
          animate={animation}
          initial="hidden"
        />
        <section className="shards-section">
          <motion.h2
            variants={fadeIn(0.5)}
            animate={animation}
            initial="hidden"
          >
            Featured Options
          </motion.h2>
          <motion.div
            variants={shardVariant}
            animate={animation}
            initial="hidden"
            className="shards-row"
          >
            <Shard name="Bristol Blue" hex="#3F6C81" />
            <Shard name="Forest Green" hex="#38614F" />
            <Shard name="Terra-cotta" hex="#A55139" />
            <Shard name="Colonial Red" hex="#6E2C2D" />
          </motion.div>
          <motion.hr
            className="divider"
            variants={growRight}
            animate={animation}
            initial="hidden"
          />
          <motion.div
            variants={fadeIn(0.6)}
            animate={animation}
            initial="hidden"
            className="link-container"
          >
            <Link to="/materials-catalogue">Our Materials ></Link>
          </motion.div>
          <motion.hr
            className="divider"
            variants={growRight}
            animate={animation}
            initial="hidden"
          />
        </section>
      </div>
    </section>
  );
}

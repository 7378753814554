import * as React from "react";
import PageWrapper from "../components/page-wrapper";
import { LandingSection } from "../sections/landing";
import { AboutUsSection } from "../sections/about";
import { ProcessSection } from "../sections/process";
import { ContactSection } from "../sections/contact";
import { MaterialSection } from "../sections/materials";
import NewLanding from "../sections/new-landing";



// markup
function IndexPage() {
  return (
    <PageWrapper>
      <main>
        <NewLanding />
        <AboutUsSection />
        <MaterialSection />
        <ProcessSection />
        <ContactSection />
      </main>
    </PageWrapper>
  );
}

export default IndexPage;

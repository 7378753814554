import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { delayed, growRight, fadeIn } from "../components/animations";
import { useInView } from "react-intersection-observer";
import "../styles/sections/process.scss";
import { Link } from "gatsby";
import processVideo1 from "../videos/process_video_1.mp4";
import processVideo2 from "../videos/process_video_2.mp4";

const delayContainer = {
  visible: {
    transition: {
      staggerChildren: 0.5,
    },
  },
};

export function ProcessSection() {
  const animation = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    }
  }, [animation, inView]);

  return (
    <section ref={ref} className="process-section">
      <div className="container">
        <motion.h1
          variants={delayed(0.5, -200)}
          animate={animation}
          initial="hidden"
        >
          Process<span>.</span>
        </motion.h1>
        <div id="video-container">
          {/* <motion.section id="video-view-container">
            <video id="video" controls>
              <source src={video} type="video/mp4" />
            </video>
            <iframe
              src="https://drive.google.com/file/d/1TCZC5rYnvUrhbPPo-souLiHaeS8nRliF/preview"
              width="640"
              height="480"
              allow="autoplay"
            ></iframe>
          </motion.section> */}
          <section id="video-text-container">
            <motion.hr
              className="divider"
              variants={growRight}
              animate={animation}
              initial="hidden"
            />
            <motion.h2
              variants={fadeIn(0.5)}
              animate={animation}
              initial="hidden"
            >
              Our Values<span>.</span>
            </motion.h2>
            <motion.div
              variants={delayContainer}
              animate={animation}
              initial="hidden"
              className="row"
            >
              <motion.section
                variants={fadeIn(0.6)}
                animate={animation}
                initial="hidden"
                className="col"
              >
                <h3>Family</h3>
                <p>
                  We believe that a big part of family is trust, so building
                  this <span>trust</span> with our clients is something we take
                  to heart. We take getting the job done seriously, and making
                  sure that you feel like work is getting done every step of the
                  way.
                </p>
              </motion.section>
              <motion.section
                variants={fadeIn(0.6)}
                animate={animation}
                initial="hidden"
                className="col"
              >
                <h3>Quality</h3>
                <p>
                  Over the years we've been in business, we've gotten to know
                  the best manufacturers who provide the{" "}
                  <span>best quality material</span> for your roof. Combined
                  with our <span>craftsmanship</span>, we'll be sure to deliver
                  a great roof that will last you for a long time!
                </p>
              </motion.section>
              <motion.section
                variants={fadeIn(0.6)}
                animate={animation}
                initial="hidden"
                className="col"
              >
                <h3>Consistency</h3>
                <p>
                  Getting the job done right once is just luck. Getting every
                  job right time and time again? That takes experience. You can
                  trust that we'll deliver on any project, no matter the size.
                </p>
              </motion.section>
            </motion.div>
            <motion.hr
              className="divider"
              variants={growRight}
              animate={animation}
              initial="hidden"
            />
            <motion.div
              variants={fadeIn(0.6)}
              animate={animation}
              initial="hidden"
              className="link-container"
            >
              <Link to="/services">See our Services ></Link>
            </motion.div>
            <motion.hr
              className="divider"
              variants={growRight}
              animate={animation}
              initial="hidden"
            />
          </section>
        </div>
        <section id="process-video-section">
          <motion.video 
            variants={fadeIn(0.6)}
            animate={animation}
            initial="hidden"
            className="process-video" 
            controls
          >
            <source src={processVideo1} type="video/mp4" />
            Your browser does not support the video tag.
          </motion.video>
          <motion.video 
            className="process-video" 
            variants={fadeIn(0.6)}
            animate={animation}
            initial="hidden"
            controls
          >
            <source src={processVideo2} type="video/mp4" />
            Your browser does not support the video tag.
          </motion.video>
        </section>
      </div>
    </section>
  );
}

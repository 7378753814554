import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { delayed, growRight, fadeIn } from "../components/animations";
import "../styles/sections/new-landing.scss";

const LOCATIONS = ["Featured", "Residential", "Business"];

const NewLanding = () => {
  const [locationIndex, updateLocationIndex] = useState(0);

  return (
    <>
      <motion.div className="container" style={{ marginBottom: "40px" }}>
        <h3>Serving Los Angeles, San Bernardino, San Diego, and more</h3>
      </motion.div>
      <section id="landing-gallery" className="container">
        <motion.div id="gallery-navbar">
          <GalleryHeader>{LOCATIONS[locationIndex]}</GalleryHeader>
          {LOCATIONS.map((loc, index) => (
            <motion.button
              className={"tab-button"}
              onClick={() => {
                updateLocationIndex(index);
                console.log(index);
              }}
            >
              {loc}
            </motion.button>
          ))}
        </motion.div>
        <motion.div id="gallery-container">
          <Gallery index={locationIndex} />
        </motion.div>
      </section>
    </>
  );
};

const GalleryHeader = ({ children }) => {
  return (
    <motion.div className="gallery-header">
      <h2>{children}</h2>
    </motion.div>
  );
};

const Gallery = ({ index }) => {
  if (index === 0) {
    return (
      <motion.div id="gallery">
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/raging-waters.jpg"
            layout="constrained"
            alt="Raging Waters"
          />
          <div className="gallery-cell-img-overlay -white">San Dimas, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/seal-beach/gated_overhead.jpg"
            layout="constrained"
            alt="Overhead view of metal roofing on seal beach property"
          />
          <div className="gallery-cell-img-overlay -white">Seal Beach, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/house-3.jpg"
            layout="constrained"
            alt="Metal wall panels on house"
          />
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/house-2.jpg"
            layout="constrained"
            alt="Metal wall panels on house"
          />
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/house-0.jpg"
            layout="constrained"
            alt="Metal wall panels on house"
          />
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/glen_oaks_1.jpg"
            layout="constrained"
            alt="Metal Roofing on Pasadena property"
          />
          <div className="gallery-cell-img-overlay -white">Pasadena, CA</div>
        </motion.div>
      </motion.div>
    );
  }
  if (index === 1) {
    return (
      <motion.div id="gallery">
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/janice_1.jpg"
            layout="constrained"
            alt="White metal roofing on Beverly Hills property."
          />
          <div className="gallery-cell-img-overlay -white">Venice Beach, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/seal-beach/gated_overhead.jpg"
            layout="constrained"
            alt="Overhead view of metal roofing on seal beach property"
          />
          <div className="gallery-cell-img-overlay -white">Seal Beach, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/oceanfront_1.jpg"
            layout="constrained"
            alt="Beachside metal roofing"
          />
          <div className="gallery-cell-img-overlay -white">Newport Beach, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/santa_ana_1.jpg"
            layout="constrained"
            alt="Large metal roofing over garden area."
          />
          <div className="gallery-cell-img-overlay -white">Santa Ana, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/tustin_money_2.jpg"
            layout="constrained"
            alt="Blue metal roof awning."
          />
          <div className="gallery-cell-img-overlay -white">Tustin, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/deleon_money.jpg"
            layout="constrained"
            alt="Overhead view of metal roofing"
          />
          <div className="gallery-cell-img-overlay -white">Long Beach, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/clarke_1.jpg"
            layout="constrained"
            alt="White metal roofing on Venice Beach property."
          />
          <div className="gallery-cell-img-overlay -white">Venice Beach, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/glen_oaks_1.jpg"
            layout="constrained"
            alt="Metal Roofing on Pasadena property"
          />
          <div className="gallery-cell-img-overlay -white">Pasadena, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/canada_1.jpg"
            layout="constrained"
            alt="Metal Roofing on La Canada property"
          />
          <div className="gallery-cell-img-overlay -white">La Canada, CA</div>
        </motion.div>
      </motion.div>
    );
  }
  if (index === 2) {
    return (
      <div id="gallery">
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/bl_2.jpg"
            layout="constrained"
            alt="Metal Roofing on Bethany Lutheran in Long Beach"
          />
          <div className="gallery-cell-img-overlay -white">Long Beach, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/preuss_1.jpg"
            layout="constrained"
            alt="Metal Roofing on Los Angeles Business"
          />
          <div className="gallery-cell-img-overlay -white">Los Angeles, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/preuss_2.jpg"
            layout="constrained"
            alt="Metal Roofing on Los Angeles Business"
          />
          <div className="gallery-cell-img-overlay -white">Los Angeles, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/bl_1.jpg"
            layout="constrained"
            alt="Metal Roofing on Bethany Lutheran in Long Beach"
          />
          <div className="gallery-cell-img-overlay -white">Long Beach, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/preuss_3.jpg"
            layout="constrained"
            alt="Metal Roofing on Los Angeles Business"
          />
          <div className="gallery-cell-img-overlay -white">Los Angeles, CA</div>
        </motion.div>
        <motion.div className="gallery-img-container">
          <StaticImage
            className="gallery-cell-img"
            src="../images/squares/raging-waters.jpg"
            layout="constrained"
            alt="Metal Roofing at Raging Waters in San Dimas"
          />
          <div className="gallery-cell-img-overlay -white">San Dimas, CA</div>
        </motion.div>
      </div>
    );
  }
  return <div>Loading...</div>;
};

// albums:
//   Featured:
//     - url: https://photos.app.goo.gl/85XLzrfmDmsKqvwk8
//       desc: New metal roofs at Raging Waters in San Dimas, CA
//     - url: https://photos.app.goo.gl/bDMsF1L2VvyNnkN98
//       desc: Dark gray metal roofing on residential property
//     - url: https://photos.app.goo.gl/F7u6H4KnRq2D9SiW6
//       desc: Custom metal panelling along a residential property
//     - url: https://photos.app.goo.gl/JdFX2QVhWPMaq3j86
//       desc: Metal roofing on property out in the desert
//     - url: https://photos.app.goo.gl/xNVFPceinkoeQf6C9
//       desc: Metal roofing on beautiful beachfront property

export default NewLanding;

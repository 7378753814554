import React, { useEffect } from "react";
import { Link } from "gatsby";
import "../styles/sections/contact.scss";
import { motion, useAnimation } from "framer-motion";
import { delayed, growRight, fadeIn } from "../components/animations";
import { useInView } from "react-intersection-observer";

export function ContactSection() {

  const animation = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    }
  }, [animation, inView]);

  return (
    <section ref={ref} className="contact-section">
      <div className="container">
        <ContactBanner animation={animation} />
        <motion.section
          variants={delayed(0.5, 200)}
          initial="hidden"
          animate={animation}
          className="contact-banner"
          className="contact-information"
        >
          <section>
            <h2>Contact</h2>
            <p>o: 626-330-2209</p>
            <p>c: 626-624-6565</p>
            <p>e: mayrac@westcoast-metalroofing.com</p>
          </section>
          <section>
            <h2>Business Info</h2>
            <p>Mon - Fri; Hours</p>
            <p>15443 Proctor Ave.</p>
            <p>Space B</p>
            <p>City of Industry, 91745 CA</p>
          </section>
          <Link to="/contact">
            Contact Us Here
          </Link>
        </motion.section>
      </div>
    </section>
  );
}

function ContactBanner({animation}) {
  return (
    <motion.div
      variants={delayed(0.5, -200)}
      initial="hidden"
      animate={animation}
      className="contact-banner"
    >
      <h2>
        Like what you see<span>?</span>
      </h2>
      <h1>
        Get in touch<span>.</span>
      </h1>
      <Link to="/contact">
        Email Us<span>!</span>
      </Link>
    </motion.div>
  );
}
